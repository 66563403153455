import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ConcessionsTreeRepository extends BaseRepository {
    baseUrl = 'management/specifications/concessions';

    /**
     * Returns tree
     *
     * @returns {*}
     */
    tree(criteria = {}) {
        return this.httpClient.post('management/concessions/concessions/tree', criteria);
    }
}
